const publicRoutes = [
  '/404',
  '/login',
  '/registrations/cleaning_company',
  '/registrations/success',
  '/invitations/[uuid]',
  '/signin/reset_password',
  '/signin/reset_password/sent_verify_email',
  '/signin/reset_password/verify',
  '/signin/reset_password/email_verify_token_mismatch',
  '/signin/reset_password/updated',
  '/cleaning_companies/invitations/[uuid]',
  '/cleaners/invitations/[uuid]',
]
export default publicRoutes
