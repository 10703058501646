import { atom, useRecoilValue, useSetRecoilState } from 'recoil'
import { useEffect } from 'react'

export const pageTitleState = atom<string>({
  key: 'pageTitleState',
  default: '',
})

export default function usePageTitle(newPageTitle: string, noEffect = false) {
  const pageTitle = useRecoilValue(pageTitleState)
  const setPageTitle = useSetRecoilState(pageTitleState)
  useEffect(() => {
    if (noEffect) return
    setPageTitle(newPageTitle)
    return () => setPageTitle('')
  }, [setPageTitle, newPageTitle, noEffect])

  return {
    pageTitle,
  }
}
