import { atom, useRecoilValue, useSetRecoilState } from 'recoil'

export type CleaningTableModeState = {
  search: boolean
  multipleSelect: boolean
  grid: boolean
}

export const cleaningTableModeState = atom<CleaningTableModeState>({
  key: 'cleaningTableModeState',
  default: {
    search: false,
    multipleSelect: false,
    grid: false,
  },
})

export function useCleaningTableMode() {
  const cleaningTableMode = useRecoilValue(cleaningTableModeState)
  const setCleaningTableMode = useSetRecoilState(cleaningTableModeState)
  const cleaningTableModeReducer = (type: keyof CleaningTableModeState) => {
    setCleaningTableMode((oldState): CleaningTableModeState => {
      switch (type) {
        case 'search':
          return {
            ...oldState,
            search: !oldState.search,
          }
        case 'multipleSelect':
          return {
            ...oldState,
            multipleSelect: !oldState.multipleSelect,
          }
        case 'grid':
          return {
            ...oldState,
            grid: !oldState.grid,
          }
      }
    })
  }

  return {
    cleaningTableMode,
    cleaningTableModeReducer,
  }
}
