import useSWR from 'swr'
import { CleanerRole } from '../types/Cleaner'

type CleaningCompany = {
  id: number
  transportRequestLink: string
  reportLostItemLink: string
}

export type User = {
  email: string
  id: number
  firstName: string
  lastName: string
  role: CleanerRole
  cleaningCompany: CleaningCompany
}

export function useLoginUser() {
  const { data, error, mutate } = useSWR<User>('/user',
    null,
    {
      shouldRetryOnError: false,
    })
  const isLoading = !data && !error
  const isLogin = error == null && data != null

  return {
    isLoading,
    isLogin,
    loginUser: data,
    mutate,
  }
}
