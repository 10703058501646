import React, {useEffect} from 'react'
import {FiAlertCircle, FiCheckCircle, FiInfo, FiX} from 'react-icons/fi'
import {flashMessageState, flashTypeState} from '../../../stores/useFlashMessage'
import {useRecoilValue, useSetRecoilState} from 'recoil'
import FlashMessagePortal from './FlashMessagePortal'

export const FlashMessage = () => {
  const message = useRecoilValue(flashMessageState)
  const type = useRecoilValue(flashTypeState)
  const setFlashMessage = useSetRecoilState(flashMessageState)
  const onClose = () => setFlashMessage('')
  const getTypeIcon = () => {
    switch (type) {
      case 'error':
        return <FiAlertCircle size="16" className="text-red-500 mx-auto" />
      case 'success':
        return <FiCheckCircle size="16" className="text-green-500 mx-auto" />
      case 'info':
        return <FiInfo size="16" className="text-blue-500 mx-auto" />
      default:
        return null
    }
  }

  const getTypeColor = () => {
    switch (type) {
      case 'error':
        return 'bg-red-100 border border-red-400 text-red-700'
      case 'success':
        return 'bg-green-100 border border-green-400 text-green-700'
      case 'info':
        return 'bg-blue-100 border border-blue-400 text-blue-700'
      default:
        return ''
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      onClose()
    }, 3000)

    return () => {
      clearTimeout(timer)
    }
  }, [onClose])

  if (message === '') return null

  return (
    <FlashMessagePortal>
      <div className={`z-10 fixed top-[56px] left-0 w-full rounded-md p-2 ${getTypeColor()}`}>
        <div className="flex items-center justify-between">
          <div className="tooltip">
            {getTypeIcon()}
            <span className="tooltiptext">{type.toUpperCase()}</span>
          </div>
          <div className="text-sm font-medium">{message}</div>
          {onClose && (
            <div className="tooltip">
              <button className="ml-4" onClick={onClose}>
                <FiX size="16" className="text-gray-500" />
              </button>
            </div>
          )}
        </div>
      </div>
    </FlashMessagePortal>
  )
}