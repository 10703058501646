import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import publicRoutes from '../../config/publicRoutes'
import { useLoginUser } from '../../stores/loginUser'
import notProtectedRoutes from '../../config/notProtectedRoutes'
import Loading from '../Loading'

type ProtectedRouteProps = {
  children: React.ReactNode
}

export default function ProtectedRoute({
  children,
}: ProtectedRouteProps): JSX.Element {
  const router = useRouter()
  const { loginUser, isLogin, isLoading } = useLoginUser()
  const inValidRouteOperation =
    !isLogin && !publicRoutes.includes(router.pathname)
  const redirectRouteFlg = isLogin && router.pathname === '/login'
  const staffGuard =
    loginUser &&
    loginUser.role === 'staff' &&
    router.pathname !== '/login' &&
    !notProtectedRoutes.includes(router.pathname)

  useEffect(() => {
    if (isLoading) return
    // 認証前に認証が必要なページにアクセスされた場合はリダイレクト
    if (!isLogin && !publicRoutes.includes(router.pathname)) {
      router.replace('/login')
    }
    // 認証後にログインページに入る場合は'/index'へリダイレクト
    else if (isLogin && router.pathname === '/login') {
      router.replace('/')
    }
    // routerは無限ループになるので入れない
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogin, router.pathname, isLoading])

  useEffect(() => {
    if (
      isLoading ||
      !loginUser ||
      router.pathname === '/login' ||
      notProtectedRoutes.includes(router.pathname)
    )
      return

    if (loginUser.role === 'staff') router.push('/403')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isLogin, router.pathname, loginUser])

  // useEffectが動くまでLoading
  if (isLoading || inValidRouteOperation || redirectRouteFlg || staffGuard)
    return <Loading />
  return <>{children}</>
}
