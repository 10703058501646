import React from 'react'
import '../styles/globals.css'
import { AppProps } from 'next/app'
import { RecoilRoot } from 'recoil'
import Layout from '../components/layouts/Layout'
import ProtectedRoute from '../components/layouts/ProtectedRoute'
import { SWRConfig } from 'swr'
import Head from 'next/head'
import useFetcher from '../libs/fetcher'
import {FlashMessage} from '../components/Elements/AppFlash'
import {initZod} from '../libs/zod'

initZod()

export default function App({ Component, pageProps }: AppProps): JSX.Element {
  const fetcher = useFetcher()
  const swrConfig = {
    fetcher: fetcher,
  }

  return (
    <SWRConfig value={swrConfig}>
      <RecoilRoot>
        <Head>
          <link rel="manifest" href="/manifest.webmanifest" />
          <link rel="apple-touch-icon" href="/icons/icon-192x192.png" />
          <title>【{process.env.NODE_ENV}】清掃インジケーター</title>
        </Head>
        <ProtectedRoute>
          <Layout>
            <>
              <FlashMessage />
              <Component {...pageProps} />
            </>
          </Layout>
        </ProtectedRoute>
      </RecoilRoot>
    </SWRConfig>
  )
}
