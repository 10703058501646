import {atom, useSetRecoilState} from 'recoil'

export type FlashMessageType = 'success' | 'error' | 'info'

export const flashMessageState = atom<string>({
  key: 'flashMessage',
  default: '',
})

export const flashTypeState = atom<FlashMessageType>({
  key: 'flashType',
  default: 'info',
})

export const useFlash = () => {
  const setFlashMessage = useSetRecoilState(flashMessageState)
  const setFlashType = useSetRecoilState(flashTypeState)

  const flash = (message: string, type: FlashMessageType = 'info') => {
    setFlashMessage(message)
    setFlashType(type)
  }

  return {
    flash,
  }
}
