import React from 'react'
import {createPortal} from 'react-dom'

const FlashMessagePortal = ({ children }: { children: React.ReactNode }) => {
  const portalRoot = document.getElementById('flash-message-portal')

  if (!portalRoot) {
    throw new Error('Portal root element not found')
  }

  return createPortal(children, portalRoot)
}

export default FlashMessagePortal
