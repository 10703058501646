import useSWR from 'swr'
import { HttpResponse } from './httpClient'
import { Invitations } from '../api/cleaner/invitations'
import { useEffect } from 'react'
import { useLoginUser } from '../stores/loginUser'
import { useRouter } from 'next/router'

export default function useCheckInvitation() {
  const router = useRouter()
  const { loginUser } = useLoginUser()
  if (loginUser == null) throw new Error('This user has not logged in.')
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore TODO:あとで直す
  const { data, mutate } = useSWR<HttpResponse<Invitations>>(
    loginUser.role === 'charge_person' &&
      'cleaner/cleaning_companies/invitations'
  )
  const loading = !data
  const isLoading = loginUser.role === 'charge_person' && loading

  useEffect(() => {
    if (data && data.data.length !== 0) {
      router.push('/cleaning_companies/invitations/' + data.data[0].hash)
    }
    // routerは無限ループになるので入れない
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return {
    isLoading,
    data,
    mutate,
  }
}
